import type { ReactNode } from 'react';
import { EmptyState, Button } from '@pledge-earth/product-language';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import type { ButtonProps } from '@pledge-earth/product-language';

import { useAppSelector } from '../../store/hooks';
import { selectEntitlements } from '../../store/user/selectors';
import { configureToIfTestMode } from '../../hooks/useNavigateWithTestMode';
import { useIsTestMode } from '../../hooks/useIsTestMode';

export const MEASUREMENT_ENTITLEMENT = 'measurement';
export const MEASUREMENT_API_ENTITLEMENT = 'measurement-api';
export const MEASUREMENT_API_BATCH_ENTITLEMENT = 'measurement-api-batch';
const MEASUREMENT_CLIENT_KEY_OPTIONAL_ENTITLEMENT = 'measurement-client-key-optional';
export const MEASUREMENT_LSP_KEY_REQUIRED_ENTITLEMENT = 'measurement-lsp-key-required';
export const MEASUREMENT_IMPORT_S3 = 'measurement-import-s3';
export const MEASUREMENT_IMPORT_EMAIL = 'measurement-import-email';
export const MEASUREMENT_IMPORT_CHAIN = 'measurement-import-chain';
export const MEASUREMENT_IMPORT_CARGOWISE = 'measurement-import-cargowise';
export const MEASUREMENT_ILEAP_HOST = 'measurement-ileap-host';
export const MEASUREMENT_FLIGHT_LOOKUP = 'measurement-flight-lookup';
export const OFFSETTING_ENTITLEMENT = 'offsetting';
export const OFFSETTING_API_ENTITLEMENT = 'offsetting-api';
export const PLATFORM_BRANDING_ENTITLEMENT = 'platform-branding';
export const PLATFORM_SUPPORT_MESSENGER_ENTITLEMENT = 'platform-support-messenger';
export const PLATFORM_CLIENTS_ENTITLEMENT = 'platform-clients';
export const PLATFORM_SUPPLIERS_ENTITLEMENT = 'platform-suppliers';
export const PLATFORM_CARGOWISE_PROXY = 'platform-proxy-cargowise';
export const PLATFORM_DISCOVER = 'platform-discover';
export const PLATFORM_DATA_COLLECTION_OUTBOUND_ENTITLEMENT = 'platform-data-collection-outbound'; // enable users to initate data collection
export const PLATFORM_DATA_COLLECTION_INBOUND_ENTITLEMENT = 'platform-data-collection-inbound'; // enable users to view data collection requests
export const PLATFORM_SHIPMENT_REFERENCE_NUMBER_ENTITLEMENT = 'platform-shipment-reference-number'; // enable users to set a custom reference number
export const PLATFORM_DIRECTORY_SUPPLIER_ENTITLEMENT = 'platform-directory-supplier'; // Allows access to the Supplier Directory
export const PLATFORM_DIRECTORY_CLIENT_ENTITLEMENT = 'platform-directory-client'; // Allows access to the Client Directory

interface EntitlementProps {
  children: ReactNode;
  fallback?: ReactNode;
  isEntitled: (entitlements: Set<string>) => boolean;
}

export function Entitlement({ children, fallback = null, isEntitled }: EntitlementProps) {
  const userEntitlements = useAppSelector(selectEntitlements);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{isEntitled(new Set(userEntitlements)) ? children : fallback}</>;
}

interface DefaultFallbackProps {
  heading: ReactNode;
  description: ReactNode;
  image: ReactNode;
}

function DefaultFallback({ heading, description, image }: DefaultFallbackProps) {
  const isTestMode = useIsTestMode();

  const primaryAction = (props: Pick<ButtonProps, 'variant' | 'size'>) => (
    <Button {...props} elementType={Link} to={configureToIfTestMode(isTestMode, '/plans')}>
      <FormattedMessage id="entitlement.upgrade" />
    </Button>
  );

  return (
    <EmptyState
      className="h-full p-0"
      variant="primary-alt"
      heading={heading}
      description={description}
      image={image}
      primaryAction={primaryAction}
    />
  );
}

Entitlement.DefaultFallback = DefaultFallback;

export function hasEntitlement(entitlement: string) {
  return (userEntitlements: Set<string>) => userEntitlements.has(entitlement);
}

export function hasOneOfEntitlements(entitlements: string[]) {
  return (userEntitlements: Set<string>) => entitlements.some((entitlement) => userEntitlements.has(entitlement));
}

export function hasAllOfEntitlements(entitlements: string[]) {
  return (userEntitlements: Set<string>) => entitlements.every((entitlement) => userEntitlements.has(entitlement));
}

export function getRequiresClientKey(isPublic: boolean, entitlements?: string[]) {
  if (!entitlements) {
    return true;
  }
  // client key is mandatory unless client have the optional entitlement
  return !isPublic && !entitlements.includes(MEASUREMENT_CLIENT_KEY_OPTIONAL_ENTITLEMENT);
}

export function getRequiresLspKey(isPublic: boolean, entitlements?: string[]) {
  if (!entitlements) {
    return true;
  }
  // lsp key is only required when client have the required entitlement
  return !isPublic && entitlements.includes(MEASUREMENT_LSP_KEY_REQUIRED_ENTITLEMENT);
}
